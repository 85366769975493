import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import CreateActivityPostingRules from './create-activity-posting-rules/create-activity-posting-rules';
import CreateActivityInstructionResources from './create-activity-instruction-resources/create-activity-instruction-resources';
import CreateActivityMainSec from './create-activity-main-sec/create-activity-main-sec';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useMediaQuery, useTheme } from '@mui/material';

import PostSuccessModal from 'app/components/common/post-success-modal/post-success-modal';
import { createActivitySchema } from 'app/utils/validation-schema/activity-schema';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { fetchActivityById, removeSingleActivity } from 'app/shared/reducers/activity-home';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { sendActivityDraft } from 'app/shared/reducers/activity/activity-draft';
import toast from 'react-hot-toast';
import { clearExistingActivities } from 'app/shared/reducers/activity-filter';
import AlertDialog from 'app/components/common/alert-dialog/alert-dialog';
import { extractIdFromUrlName } from 'app/utils/common-utils/common-utils';

const EditActivity = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [createActivityData, setCreateActivityData] = useState<{}>({});
  const [responseUrl, setResponseUrl] = useState<string>('');
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const currentActivity = useAppSelector(state => state.homeActivity.singleActivity);
  // const isFetchingActivity = useAppSelector(state => state.homeActivity.loading);
  const [callCount, setCallCount] = useState(0);
  const [confirmPayment, setConfirmPayment] = React.useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [createBody, setCreateBody] = useState(null);
  const [passId, setPassId] = React.useState<any>();
  const [payload, setPayload] = React.useState<any>();
  const myProfileId = useAppSelector(state => state.authentication.account.id);
  const source = searchParams.get('s');
  const [isError, setError] = useState(false);
  const [activityData, setActivityData] = useState<any>(null);
  const [isFetchingActivity, setIsFetchingActivity] = useState(true);

  const initialValues = {
    categoryID: '',
    header: '',
    subCategoryID: '',
    activityTypeId: '',
    goalId: '',
    quantity: '',
    details: '',
    bloodTypeId: '',
    units: '',
    multimedia: [],
    ageLimit: '',
    includeMicroFunding: false,
    amountToRaise: '',
    place: '',
    online: false,
    activityStartTime: '',
    activityEndTime: '',
    startDate: '',
    postAsOrganisation: false,
    instructions: [],
    resources: [],
    urgent: false,
    makeThisPrivateActivity: false,
    latitude: '',
    longitude: '',
    onlineUrl: '',
    activityFrequencyId: '',
    endDate: '',
    currency: '',
    customFrequency: [
      {
        days: '',
        weeks: '',
        weekDays: [],
        months: '',
      },
    ],
    lostPet: {
      petCategoryID: '',
      animal: '',
      nameOfThePet: '',
      breed: '',
      color: '',
      dateLostOn: '',
      reward: 0,
      currencyCode: '',
      keyIdentifier: '',
      countryCode: '',
      phoneNumber: '',
      subCategoryID: '',
    },
  };
  useEffect(() => {
    const status = searchParams.get('status');
    if (status === 'success') {
      setResponseUrl(localStorage.getItem('responseUrl'));
      setPdfUrl(null);
      setTimeout(() => {
        setOpen(true);
      }, 900);
    }
  }, [searchParams]);

  const [paymentLoading, setPaymentLoading] = useState(false);
  const handlePayment = () => {
    handleEditActivitySubmit(createBody, 'PRIVATE')
      .then(res => {
        setPaymentLoading(true);
        console.log('checkmine', res);
        localStorage.setItem('responseUrl', res.link);
        localStorage.setItem('pdfUrl', res.pdfUrl);
        const activityId = res?.link.substring(res?.link.lastIndexOf('/') + 1);
        setPassId(activityId);
        const userId = myProfileId;
        handleGetCatalogue(2);
      })
      .catch(err => {
        setPaymentLoading(false);
        console.log(err);
      });
  };
  const handleGetCatalogue = async id => {
    try {
      const response = await axios.get<any>(`api/payment-service/product-catalogue?eventType=${id}`);
      if (response.status === 201 || response.status === 200) {
        setPaymentLoading(true);
        setPayload(response.data);
      }
    } catch (error) {
      setPaymentLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (payload?.productId) handlePaymentSite();
  }, [payload?.productId]);

  const handlePaymentSite = async () => {
    setPaymentLoading(true);
    let passData = {
      eventId: extractIdFromUrlName(passId).toString(),
      eventType: 2,
      quantity: 1,
      productId: payload?.productId,
    };

    localStorage.setItem('passEventId', passData?.eventId);
    localStorage.setItem('passEventType', JSON.stringify(passData?.eventType));

    try {
      const response = await axios.post<any>('api/payment-service/checkout', passData);
      if (response.status === 201 || response.status === 200) {
        const checkoutUrl = response?.data?.checkoutUrl;
        if (checkoutUrl) {
          setPaymentLoading(false);
          // setOpen(true);
          window.location.href = checkoutUrl; // Open checkout URL in current tab
        } else {
          setPaymentLoading(false);
          console.error('Checkout URL not found.');
        }
      }
    } catch (error) {
      setPaymentLoading(false);
      console.log(error);
    }
  };

  const handleEditActivitySubmit = async (activity, type): Promise<any> => {
    try {
      const axiosResponse = await axios.put<any>(`api/activities/${id}`, activity);

      if (axiosResponse.status === 201 || axiosResponse.status === 200) {
        dispatch(clearExistingActivities());

        if (type !== 'PRIVATE') {
          if (axiosResponse.data?.link) {
            setResponseUrl(axiosResponse?.data?.link);
            if (axiosResponse?.data?.pdfUrl) {
              setPdfUrl(axiosResponse?.data?.pdfUrl);
            }
            setOpen(true);
          }
        }
        return axiosResponse.data;
      }
      return axiosResponse.data; // Return the data from the response
    } catch (error) {
      console.log(error);
      throw error; // Re-throw the error to handle it elsewhere if needed
    }
  };

  const formik = useFormik({
    initialValues:
      (currentActivity != null && {
        ...currentActivity,
        includeMicroFunding: currentActivity?.includeMicroFunding == true ? true : false,
        instructions:
          currentActivity?.instructions?.map((item: any) => ({
            instruction: item.instruction,
            id: item.id,
            activityId: item.activityId,
            isEditing: false,
          })) || [],
        resources: currentActivity?.resources,
        onlineUrl: currentActivity?.online === false ? '' : currentActivity?.onlineUrl,
        makeThisPrivateActivity: currentActivity?.privateActivity ?? false,
        endDate: currentActivity?.endDate,
        customFrequency: currentActivity?.customFrequency?.map((frequency: any) => ({
          ...frequency,
          weekDays: frequency.weekDays || [], 
        })) || initialValues.customFrequency,
      }) ||
      initialValues,
    enableReinitialize: true,
    validationSchema: createActivitySchema,
    onSubmit: (values, { resetForm }) => {
      const updatedValues = {
        ...values,
        instructions: values.instructions.map(item => ({
          instruction: item.instruction,
          id: item.id ?? null, // Retain ID for existing items
          activityId: item.activityId ?? id, // Assign activityId if missing
        })),
      };

      if (updatedValues?.makeThisPrivateActivity && !currentActivity?.privateActivity) {
        setConfirmPayment(true);
        setCreateBody(updatedValues);
      } else {
        handleEditActivitySubmit(updatedValues, 'NORMAL');
      }
      // handleEditActivitySubmit(values);
      resetForm();
    },
  });

  const handleModalClose = () => {
    setOpen(false);
    navigate('/');
  };

  useEffect(() => {
    const fetchActivity = async () => {
      if (id !== undefined) {
        try {
          const response = await dispatch(fetchActivityById(id));
          setActivityData(response.payload);
          setIsFetchingActivity(false);
          // Increment callCount after fetching activity
          // setCallCount(prevCount => prevCount + 1);
        } catch (error) {
          console.log(error);
        }
      }
    };

    // Check if callCount is less than 2 before making the second call
    // if (callCount < 2) {
    //   fetchActivity();
    // }
    fetchActivity();
  }, [id, dispatch]);

  const handleNavigationArrowButton = async () => {
    if (source != null) {
      const response = await dispatch(sendActivityDraft(formik.values));
      if (sendActivityDraft.fulfilled.match(response)) {
        // toast.success('Draft saved successfully');
        navigate(-1);
      } else if (sendActivityDraft.rejected.match(response)) {
        toast.error('Failed to save draft');
      }
    } else {
      navigate(-1);
    }
  };
  return (
    <div className="mx-4 py-2" style={{ height: '100vh', overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: 'none' }}>
      <style>
        {`
         ::-webkit-scrollbar {
        width: 0;
        background: transparent; 
      }     
      ::-webkit-scrollbar-thumb {
        background: transparent;
      }
    `}
      </style>
      <div className="row   py-3">
        <div className="col-3">
          <ArrowCircleLeftIcon
            onClick={() => {
              handleNavigationArrowButton();
              // navigate(-1);
              // dispatch(removeSingleActivity());
            }}
            sx={{
              cursor: 'pointer',
              color: isDarkMode ? '#FFCE00' : '#775fff',
            }}
          />
        </div>
        <div className="col-9">
          <Typography variant="h4" sx={{ color: 'secondary.main' }}>
            Edit activity
          </Typography>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        {isFetchingActivity && currentActivity !== null ? (
          <p>Loading...</p>
        ) : (
          <div className="row">
            <div className="col-md-3 d-none d-sm-block ">
              <CreateActivityPostingRules formik={formik} />
            </div>
            <div className="col-sm-12 col-md-9 ">
              <div className="row">
                <div className="col-sm-12 col-md-8">
                  <CreateActivityMainSec setError={setError} formik={formik} setCreateActivityData={setCreateActivityData} />
                </div>
                <div className="col-sm-12 col-md-4">
                  <CreateActivityInstructionResources
                    isError={isError}
                    formik={formik}
                    setCreateActivityData={setCreateActivityData}
                    onCancel={handleNavigationArrowButton}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
      <PostSuccessModal
        handleModalClose={handleModalClose}
        fullScreen={fullScreen}
        open={open}
        onClose={handleModalClose}
        responseUrl={responseUrl}
        pdfUrl={pdfUrl}
        message={'Your activity has been updated successfully'}
      />
      <AlertDialog
        paymentLoading={paymentLoading}
        agreeAction={handlePayment}
        title=""
        description="Creating a 'By Invitation Only' activity is a paid service. Do you wish to continue?"
        open={confirmPayment}
        setAlertOpen={setConfirmPayment}
        closeButtonText="Cancel"
      />
    </div>
  );
};

export default EditActivity;
